<template>
    <header id="header" class="header">
        <div class="container">
            <div class="row" v-if="displayWeb" >
                <div class="col-lg-8 col-sm-12">
                    <div class="text-container">
                        <h1 class="h1-small text-black">"<span class="green">Revolutionize</span> Your Institute: Manage Enquiries, Student Details, Create a Website, and Get Actionable Insights."</h1>
                        <p class="p-small">From the small stuff to the big picture, organizes the work so teams know what to do, why it matters, and how to get it done.</p>
                        <a class="btn-solid-lg" href="https://play.google.com/store/apps/details?id=com.aintrie.teb">Download the App</a>
                        <a class="btn-solid-lg watch-video" href="https://www.youtube.com/watch?v=vs5JSiYJHPU">Watch the Video</a>
                    </div>
                </div> 
                <div class="col-lg-4 col-sm-12">
                    <div class="image-container img-fluid">
                        <img class="" src="assets/images/AintrieBgImg.png" alt="alternative" />
                    </div> 
                    <!-- <div class="image-container">
                        <v-carousel
                            cycle
                            height="400"
                            hide-delimiter-background
                            show-arrows="hover"
                        >
                            <v-carousel-item
                            v-for="(slide, i) in slides"
                            :key="i"
                            >
                            <v-sheet
                                :color="colors[i]"
                                height="100%"
                            >
                                <div class="d-flex fill-height justify-center align-center">
                                <div class="text-h2">
                                    {{ slide }} Slide
                                </div>
                                </div>
                            </v-sheet>
                            </v-carousel-item>
                        </v-carousel> 
                    </div> -->
                </div> 
            </div> 

            <div class="row" v-if="displayMobile">
                <div class="col-lg-8 col-sm-12">
                    <div class="text-container">
                        <h1 class="h1-small text-black">Manage Your Institute</h1>
                        <p class="p-large">Manage students, enquiries and every information at one place with Aintrie</p>
                        <a class="playstore-btn" href="https://play.google.com/store/apps/details?id=com.aintrie.teb">
                            <img src="assets/images/PlayStoreButton.png" alt="alternative" />
                        </a>
                        <!-- <a class="appstore-btn">
                            <img src="assets/images/AppStoreButton.png" alt="alternative" />
                        </a> -->
                        
                        <!-- <a class="btn-solid-lg" href="https://play.google.com/store/apps/details?id=com.aintrie.teb">Download the App</a>
                        <a class="btn-solid-lg watch-video" href="https://www.youtube.com/watch?v=vs5JSiYJHPU">Watch the Video</a> -->
                    </div>
                </div> 
                <div class="col-lg-4 col-sm-12">
                    <div class="image-container img-fluid">
                        <img class="" src="assets/images/AintrieBgImg.png" alt="alternative" />
                    </div> 
                    
                </div> 
            </div> 
        </div> 
    </header>
</template>

<script>
export default {
    data() {
        return {
            windowWidth: window.innerWidth,
            displayMobile: false,
            displayWeb: false,
            colors: [
              'indigo',
                'warning',
                'pink darken-2',
                'red lighten-1',
                'deep-purple accent-4',
            ],
            slides: [
                'First',
                'Second',
                'Third',
                'Fourth',
                'Fifth',
            ],
        }
    },
    mounted() {
        this.mobile();
    },
    methods: {
        mobile() {
            // window.innerHeight
            console.log(this.windowWidth);
            if(this.windowWidth > 1000) {
                this.displayWeb = true;
                this.displayMobile = false;
            } else {
                this.displayWeb = false;
                this.displayMobile = true;
            }
            // this.display = !this.display;
        }
    }
}
</script>
<template>
    <!-- <div class="d-flex align-center flex-column"  id="services"> -->
    <div class="cards-1 bg-gray"  id="services">
        <!-- <div class="text-subtitle-2">Explore our features</div> -->
        <div class="row">
            <div class="col-lg-12">
                <h2 class="explore-feature-heading text-black">Explore our Features</h2>
            </div> 
        </div>

        <v-container class="bg-surface-variant">

            <v-row  class="d-flex justify-space-around" no-gutters>
                <v-col
                cols="12"
                sm="12"
                lg="4"
                >
                    <v-card title="" text=""  class="elevation-0 text-left">
                        <template v-slot:title>
                            <div class="sub-feature-heading">
                            <strong class="green">Manage</strong> <strong>all the Enquiries</strong>
                            </div>
                        </template>
                        <v-card-text class="">
                            <div class="list-feature text-black">
                            <p>It includes all the enquires asked by students.</p>
                            <p>The app allows users to create enquiries by giving their mobile number, email, name, area and school/college name.</p>
                            <p>Inquiries can be edited by changing information or adding follow-ups.</p>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <!-- <v-btn variant="plain" color="#41af00" ><strong class="text-green">Learn more</strong></v-btn> -->
                            <div class="text-center">
                                <v-dialog
                                v-model="dialog1"
                                width="auto"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                        variant="plain" color="#41af00"
                                        v-bind="props"
                                        >
                                        Learn More
                                        </v-btn>
                                    </template>

                                    <v-card class="text-center">
                                        <v-card-title>
                                            <strong class="green">Manage</strong> <strong>Enquiries</strong>
                                        </v-card-title>
                                        <v-card-text class="text-left">
                                            <p>The app encompasses a system for handling student inquiries, providing a centralized location for keeping track of all the questions and requests received from students.</p>
                                            <p>Users can easily create inquiries using the app, providing their mobile number, email, name, area, and school/college name to provide context and enable prompt follow-up.</p>
                                            <p>Moreover, inquiries can be easily edited using the app, providing the option to change any of the given information or add follow-ups to provide additional details or clarification.</p>
                                            <p>This streamlined approach to managing inquiries can help institutions better serve their students and provide a more efficient and effective means of communication.</p>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="#41af00" block @click="dialog1 = false">Close</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </div>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col
                cols="10"
                sm="12"
                lg="4"
                >
                    <img src="/assets/images/ManageEnquiries.png" alt="Manage Enquiries" class="feature-img" />
                </v-col>
            </v-row>

            <v-row  class="d-flex justify-space-around" no-gutters>
                <v-col
                cols="10"
                sm="12"
                lg="4"
                v-if="displayWeb"
                >
                    <img src="/assets/images/ManageWebsite.png" alt="Manage Website" class="feature-img" />
                </v-col>
                
                <v-col
                cols="12"
                sm="12"
                lg="4"
                >
                    <v-card title="" text="" class="elevation-0 text-left">
                        <template v-slot:title >
                            <div class="sub-feature-heading">
                            <strong class="green">Manage</strong> <strong>your website</strong>
                            </div>
                        </template>
                        <v-card-text>
                            <div class="list-feature text-black">
                            <p>Our app provides a website builder for creating a professional institute website with just a few clicks.</p>
                            <p>Display your courses, faculty, facilities, and other information to help prospective students make an informed decision.</p>
                            <p>Aintrie saves time, increases efficiency, and gives your institute a deserving online presence.</p>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <!-- <v-btn
                            color="#41af00"
                            @click="snackbar = true"
                            >
                            Learn More
                            </v-btn> -->
                            <!-- <v-btn variant="plain" ccolor="#41af00" @click="snackbar = true"><strong class="text-green">Learn more</strong></v-btn> -->
                            
                            <div class="text-center">
                                <v-dialog
                                v-model="dialog2"
                                width="auto"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                        variant="plain" color="#41af00"
                                        v-bind="props"
                                        >
                                        Learn More
                                        </v-btn>
                                    </template>

                                    <v-card class="text-center">
                                        <v-card-title>
                                            <strong class="green">Manage</strong> <strong>your Website</strong>
                                        </v-card-title>
                                        <v-card-text class="text-left">
                                            <p>Aintrie offers an app that includes a website builder, enabling you to create a professional website for your institute with minimal effort.</p>
                                            <p>The website builder allows you to showcase your institute's courses, faculty, facilities, and other relevant information, providing prospective students with the necessary details to make informed decisions.</p>
                                            <p>By utilizing Aintrie's app, you can save time, increase efficiency, and give your institute the online presence it deserves, making it easier for students to find and learn about your institution.</p>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="#41af00" block @click="dialog2 = false">Close</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </div>

                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col
                cols="10"
                sm="12"
                lg="4"
                v-if="displayMobile"
                >
                    <img src="/assets/images/ManageWebsite.png" alt="Manage Website" class="feature-img" />
                </v-col>
            </v-row>

            <v-row  class="d-flex justify-space-around" no-gutters>
                <v-col
                cols="12"
                sm="12"
                lg="4"
                >
                    <v-card title="" text="" class="elevation-0 text-left">
                        <template v-slot:title>
                            <div class="sub-feature-heading">
                                <strong class="green">Manage</strong> <strong>Insights</strong>
                            </div>
                        </template>
                        <v-card-text>
                            <div class="list-feature text-black">
                            <p>Get insights that will help you data driven decisions about your institute.</p>
                            
                            <p>Track the performance of your admissions process, including the number of enquiries, applications, and enrolments.</p>
                            <p>You'll have the data you need to make informed decisions and drive success for your institute.</p>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <!-- <v-btn variant="plain" color="#41af00"><strong class="text-green">Learn more</strong></v-btn> -->
                            <div class="text-center">
                                <v-dialog
                                v-model="dialog3"
                                width="auto"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                        variant="plain" color="#41af00"
                                        v-bind="props"
                                        >
                                        Learn More
                                        </v-btn>
                                    </template>

                                    <v-card class="text-center">
                                        <v-card-title>
                                            <strong class="green">Manage</strong> <strong>Insights</strong>
                                        </v-card-title>
                                        <v-card-text class="text-left">
                                            <p>By utilizing our app, you can gain valuable insights that will enable you to make data-driven decisions about your institute.</p>
                                            <p>The app provides a comprehensive overview of your institute's admissions process, allowing you to track key performance metrics such as the number of enquiries, applications, and enrollments.</p>
                                            <p>With this information at your fingertips, you'll be equipped to make informed decisions about your institute's strategy and drive success for your institution.</p>
                                            <p>By monitoring the performance of your admissions process, you can identify areas for improvement and make necessary adjustments to ensure that you're reaching the right audience, providing the right information, and ultimately driving enrolments. With access to this data, you can make informed decisions about your institute's marketing efforts, admissions process, and more, helping to set your institution up for success in the long term.</p>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="#41af00" block @click="dialog3 = false">Close</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </div> 
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col
                cols="10"
                sm="12"
                lg="4"
                >
                    <img src="/assets/images/ManageInsights.png" alt="Manage Insights" class="feature-img" />
                    <!-- <v-img
                        :width="400"
                        :height="335"
                        aspect-ratio="16/9"
                        cover
                        src="/assets/images/ManageInsights.png"
                        
                    ></v-img> -->
                </v-col>
            </v-row>
        </v-container>

        <!-- <button class="btn btn-primary" @click="mobile">Toogle</button> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            windowWidth: window.innerWidth,
            displayMobile: false,
            displayWeb: false,
            dialog1: false,
            dialog2: false,
            dialog3: false
        }
    },
    mounted() {
        this.mobile();
    },
    methods: {
        mobile() {
            // window.innerHeight
            console.log(this.windowWidth);
            if(this.windowWidth > 1000) {
                this.displayWeb = true;
                this.displayMobile = false;
            } else {
                this.displayWeb = false;
                this.displayMobile = true;
            }
            // this.display = !this.display;
        }
    }
}
</script>

<style>
.v-row {
    margin-bottom: 200px;
}
.list-feature {
	text-align: left;
    font-size: medium;
}
.list-feature li {
	margin-bottom: 20px;
    list-style-type: none;
}
.list-feature p{
    margin-bottom: 20px;
    color: black;
    font-size: small;
}
.explore-feature-heading {
    margin-top: 20px
}
.feature-img {
	width: 80%;
	width: 80%;
	/* height: 80%; */
}
.sub-feature-heading {
	margin-top: 10px;
	margin-bottom: 15px;
}

.v-dialog {
    width: 50%;
}

@media (max-width: 992px) {
    /* .list-feature {
		font-size: 140%;
	} */
    .list-feature p {
		font-size: small;
	}
    .v-dialog {
        width: 100%;
    }
    .v-card-text p {
        font-size: small;
    }
    .sub-feature-heading {
        font-size: x-large;
    }
}
</style>
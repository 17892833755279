<template>
    <div class="cards-2 bg-gray" id="testimonials">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2 class="h2-heading">Customer Testimonials</h2>
                </div> 
            </div>
            <div class="row">
                <div class="col-lg-12">
                    
                    <div class="card">
                        <img class="quotes" src="assets/images/quotesss.svg" alt="alternative" />
                        <div class="card-body">
                            <p class="testimonial-text">"Enquiry management by this app is really very easy and accurate. Not a single enquiry is left over."</p>
                            <div class="testimonial-author">Amit Raghuvanshi</div>
                            <!-- <div class="occupation">General Manager, Presentop</div> -->
                        </div>
                        <!-- <div class="gradient-floor red-to-green"></div> -->
                        <div class="gradient-floor red-to-green"></div>
                    </div>
                    
                    
                    <div class="card">
                        <img class="quotes" src="assets/images/quotesss.svg" alt="alternative" />
                        <div class="card-body">
                            <p class="testimonial-text">"Web site creation from this app is very user friendly. Now I don't need to hire any developer for this task."</p>
                            <div class="testimonial-author">Tanmay Singh</div>
                            <!-- <div class="occupation">General Manager, Presentop</div> -->
                        </div>
                        <div class="gradient-floor blue-to-purple"></div>
                    </div>
                    
                    
                    <div class="card">
                        <img class="quotes" src="assets/images/quotesss.svg" alt="alternative" />
                        <div class="card-body">
                            <p class="testimonial-text">"Giving all the business benefits in such a small amount is awesome. I am saving a lot of money by using this app."</p>
                            <div class="testimonial-author">Ritesh Badhai</div>
                            <!-- <div class="occupation">General Manager, Presentop</div> -->
                        </div>
                        <div class="gradient-floor purple-to-green"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.card {
    margin-bottom: 5rem;
}
</style>
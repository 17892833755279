<template>
    <div class="copyright bg-gray bottom-box">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-2">
                    <ul class="list-unstyled li-space-lg p-small">
                        <li class="text-white">PC Colony, Kankarbagh, Patna-20</li>
                        <!-- <li><a href="#">Terms & Conditions</a></li> -->
                        <li class="text-white"><a href="#" class="text-white">@2022 Coding Age</a></li>
                    </ul>
                </div> 
                <!-- <div class="col-lg-3 col-md-12 col-sm-12">
                    <p class="p-small statement">Copyright © <a href="https://www.codingage.biz/">2023 by Coding Age </a></p>
                </div>  -->
                <div class="col-lg-6 col-md-6 col-sm-8 text-right">
                    <a href="https://play.google.com/store/apps/details?id=com.aintrie.teb">
                        <img src="assets/images/PlayStoreButton.png" alt="alternative" />
                    </a>
                    <!-- <a>
                        <img src="assets/images/AppStoreButton.png" alt="alternative" />
                    </a> -->
                </div>
                <!-- <div class="col">
                    <button>
                            <img src="assets/images/AppStoreButton.png" alt="alternative" />
                        </button>
                </div> -->
                <!-- <div class="col-lg-3 col-md-12 col-sm-12"> -->
                    <!-- <p class="p-small statement">Distributed by <a href="https://masukmia.com" target="_blank">Masuk Mia </a></p>
                    <p class="p-small statement"> -->
                        <!-- <span> -->
                        <!-- <button>
                            <img src="assets/images/PlayStoreButton.png" alt="alternative" />
                        </button> -->
                        <!-- <button>
                            <img src="assets/images/AppStoreButton.png" alt="alternative" />
                        </button> -->
                        <!-- </span> -->
                    <!-- </p> -->
                    <!-- <p class="p-small statement">Distributed by <a href="https://masukmia.com" target="_blank">Masuk Mia </a></p> -->
                <!-- </div>  -->
            </div> 
        </div> 
    </div>
    <button id="myBtn">
        <img src="assets/images/up-arrow.png" alt="alternative" />
    </button>
</template>
<template>
    <div id="details" class="basic-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-xl-6 col-sm-12">
                    <div class="text-container text-left">
                        <!-- <div class="section-title">HOW IT WROKS</div> -->
                        <h2 class="text-black">It is all in one institute management app</h2>
                        <p class="text-black">Skip that endless process of maintaining registers for entries, hiring developers for building/updating websites to manage enquiries. With Aintrie all admin needs to do is feed in the information about the institution and get a professional website. Track and follow-up on all enquiries in different stages until closure. Make data driven institutes about your institute with the insights you get through our app.</p>
                        <!-- <a class="btn-solid-reg" href="#contact">Get quote</a> -->
                    </div> 
                </div> 
                <div class="col-lg-4 col-xl-4 col-sm-4" v-if="displayWeb">
                    <div class="image-container text-right">
                        <img class="img-fluid" src="assets/images/corrected-login-image.png" alt="alternative" />
                    </div> 
                </div> 
                
            </div>
        </div> 
    </div>
</template>


<script>
export default {
    data() {
        return {
            windowWidth: window.innerWidth,
            displayMobile: false,
            displayWeb: false
        }
    },
    mounted() {
        this.mobile();
    },
    methods: {
        mobile() {
            // window.innerHeight
            console.log(this.windowWidth);
            if(this.windowWidth > 1000) {
                this.displayWeb = true;
                this.displayMobile = false;
            } else {
                this.displayWeb = false;
                this.displayMobile = true;
            }
            // this.display = !this.display;
        }
    }
}
</script>
<template>
    <div id="contact" class="form-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="text-container">
                        <!-- <div class="section-title">GET QUOTE</div> -->
                        <h2>For Any Assistance Required Please Reach Out</h2>
                        <p>Telephone : +91 9693243217</p>
                        <p>Mail : info@codingage.biz</p>
                        <!--<ul class="list-unstyled li-space-lg">
                            <li class="d-flex">
                                <i class="fas fa-square"></i>
                                <div class="flex-grow-1">Vel maximus nunc aliquam ut. Donec semper, magna a pulvinar</div>
                            </li>
                            <li class="d-flex">
                                <i class="fas fa-square"></i>
                                <div class="flex-grow-1">Suscipit sit amet quis lorem. Sed risus ipsum, egestas mare</div>
                            </li>
                            <li class="d-flex">
                                <i class="fas fa-square"></i>
                                <div class="flex-grow-1">Sem pulvinar suscipit sit amet quis lorem. Sed risus</div>
                            </li>
                        </ul> -->
                    </div> 
                </div> 
                <div class="col-lg-6">
                    
                    <form>  
                        <div class="form-group">
                            <input type="text" class="form-control-input" placeholder="Name" required />
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control-input" placeholder="Email" required />
                        </div>
                        <!-- <div class="form-group">
                            <input type="text" class="form-control-input" placeholder="Industry" required />
                        </div> -->
                        <div class="form-group">
                            <textarea name="message" placeholder="Leave us message" class="form-control-input" id="" cols="30" rows="6"></textarea>
                            <!-- <input type="text" class="form-control-input" placeholder="Your product" required /> -->
                        </div>
                        <div class="form-group">
                            <!-- <v-btn class="bg-#41af00 text-center form-control-submit-button"
                            :color="isHovering ? 'black' : 'white'"
                            rounded="pill"

                            >
                            Submit
                            </v-btn> -->
                            <button type="submit" class="form-control-submit-button text-white"
                            >Submit</button>
                        </div>
                    </form>
                    
                </div> 
            </div>
        </div> 
    </div>
</template>

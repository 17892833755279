<template>
    <!-- <div class="slider-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h4>Trusted by over <span class="green">50+</span> customers</h4>
                    <hr class="section-divider" />
                </div> 
            </div>
        </div> 
    </div> -->
    <div class="basic-3" id="faq">
        <div class="container"> 
            <div class="row">
                <div class="col-lg-12">
                    <!-- <div class="tab">
                        <button class="tablinks" onclick="openCity(event, 'London')" id="defaultOpen">General</button>
                        <button class="tablinks" onclick="openCity(event, 'Paris')">Payment</button>
                        <button class="tablinks" onclick="openCity(event, 'Tokyo')">Privacy</button>
                    </div> -->
                    <div class="text-container">
                        <h3>FAQs</h3>
                    <!-- <div class="tab">
                        <button class="tablinks" @click="makeDisplay1" id="defaultOpen"><i class="fa icon-text-style" v-bind:class="hoverStyle">&#xf023; &nbsp;&nbsp;&nbsp; GENERAL</i></button>
                        <button class="tablinks" v-bind:class="{active: activeEffectValue}" @click="makeDisplay2"><i class="fa icon-text-style">&#xf09d; &nbsp;&nbsp; PAYMENT</i></button>
                        <button class="tablinks" @click="makeDisplay3"><i class="fa icon-text-style">&#xf013; &nbsp;&nbsp; SERVICES</i></button>
                    </div> -->
                    <div class="tab" v-if="displayWeb">
                        <i class="fa tablinks icon-text-style" v-bind:class="{active1: activeEffectValue1}" @click="makeDisplay1" id="defaultOpen">&#xf023; &nbsp;&nbsp;&nbsp; GENERAL</i> <br>
                        <i class="fa tablinks icon-text-style" v-bind:class="{active2: activeEffectValue2}" @click="makeDisplay2">&#xf09d; &nbsp;&nbsp; PAYMENT</i> <br>
                        <i class="fa tablinks icon-text-style" v-bind:class="{active3: activeEffectValue3}" @click="makeDisplay3">&#xf013; &nbsp;&nbsp; SERVICES</i>
                    </div>
                    <div class="tab" v-if="displayMobile">
                        <i class="fa tablinks icon-text-style" v-bind:class="{active1: activeEffectValue1}" @click="makeDisplay1" id="defaultOpen">&#xf023; &nbsp;&nbsp;&nbsp; GENERAL</i>
                        <i class="fa tablinks icon-text-style" v-bind:class="{active2: activeEffectValue2}" @click="makeDisplay2">&#xf09d; &nbsp;&nbsp; PAYMENT</i>
                        <i class="fa tablinks icon-text-style" v-bind:class="{active3: activeEffectValue3}" @click="makeDisplay3">&#xf013; &nbsp;&nbsp; SERVICES</i>
                    </div>

                    <div id="London" class="tabcontent text-left" v-if="display1">
                        <h5><i class="fa icon-style">&#xf023;</i>General</h5>
                        <div>
                            <v-expansion-panels>
                                <v-expansion-panel
                                    title="How often should the app be updated ?"
                                    text="You will have a facility to report the issue got in this app while using this. Once you report the issue, we will start to look into and fix it as soon as possible."
                                >
                                </v-expansion-panel>
                                <v-expansion-panel
                                    title="How do I create a website with Aintrie ?"
                                    text="Our website builder allows you to create a professional-looking website for your institute in just a few clicks. Simply add your content on the app, and publish your site."
                                >
                                </v-expansion-panel>
                                <v-expansion-panel
                                    title="How does Aintrie help in managing student details ?"
                                    text="Our app provides a centralized database for storing student information, making it easy for you to manage and track details about prospective and enrolled students."
                                >
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                    </div>

                    <div id="Paris" class="tabcontent text-left" v-if="display2">
                        <h5><i class="fa icon-style">&#xf09d;</i>Payment</h5>
                        <div>
                            <v-expansion-panels>
                                <v-expansion-panel
                                    title="How often should the app be updated ?"
                                    text="You will have a facility to report the issue got in this app while using this. Once you report the issue, we will start to look into and fix it as soon as possible."
                                >
                                </v-expansion-panel>
                                <v-expansion-panel
                                    title="How do I create a website with Aintrie ?"
                                    text="Our website builder allows you to create a professional-looking website for your institute in just a few clicks. Simply add your content on the app, and publish your site."
                                >
                                </v-expansion-panel>
                                <v-expansion-panel
                                    title="How does Aintrie help in managing student details ?"
                                    text="Our app provides a centralized database for storing student information, making it easy for you to manage and track details about prospective and enrolled students."
                                >
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                    </div>

                    <div id="Tokyo" class="tabcontent text-left" v-if="display3">
                        <h5><i class="fa icon-style">&#xf013;</i>Services</h5>
                        <div>
                            <v-expansion-panels>
                                <v-expansion-panel
                                    title="How often should the app be updated ?"
                                    text="You will have a facility to report the issue got in this app while using this. Once you report the issue, we will start to look into and fix it as soon as possible."
                                >
                                </v-expansion-panel>
                                <v-expansion-panel
                                    title="How do I create a website with Aintrie ?"
                                    text="Our website builder allows you to create a professional-looking website for your institute in just a few clicks. Simply add your content on the app, and publish your site."
                                >
                                </v-expansion-panel>
                                <v-expansion-panel
                                    title="How does Aintrie help in managing student details ?"
                                    text="Our app provides a centralized database for storing student information, making it easy for you to manage and track details about prospective and enrolled students."
                                >
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                    </div>
                    </div>
                    <!-- <div class="text-container">
                        <h1>FAQs</h1>
                        <h2 @click="toogleAns1" class="collapsible">{{ que1 }}</h2>
                        <p class="p-large" v-if="showAns1">{{ ans1 }}</p>

                        <h2 @click="toogleAns2" class="collapsible">{{ que2 }}</h2>
                        <p class="p-large" v-if="showAns2">{{ ans2 }}</p>
                            
                        <h2 @click="toogleAns3" class="collapsible">{{ que3 }}</h2>
                        <p class="p-large" v-if="showAns3">{{ ans3 }}</p>
                    </div>  -->

                </div> 
            </div>
        </div> 
    </div> 
</template> 


 <script>
export default {
    data() {
        return {
            que1: "How often should the app be updated ?",
            ans1: "You will have a facility to report the issue got in this app while using this. Once you report the issue, we will start to look into and fix it as soon as possible.",
            showAns1: false,

            que2: "How do I create a website with Aintrie ?",
            ans2: "Our website builder allows you to create a professional-looking website for your institute in just a few clicks. Simply add your content on the app, and publish your site.",
            showAns2: false,

            que3: "How does Aintrie help in managing student details ?",
            ans3: "Our app provides a centralized database for storing student information, making it easy for you to manage and track details about prospective and enrolled students.",
            showAns3: false,

            display1: true,
            display2: false,
            display3: false,
            activeEffectValue1: false,
            activeEffectValue2: false,
            activeEffectValue3: false,
            displayMobile: false,
            displayWeb: false,
            windowWidth: window.innerWidth,
        }
    },
    mounted() {
        this.mobile(),
        this.makeDisplay1()
    },
    methods: {
        toogleAns1() {
            this.showAns1 = !this.showAns1;
        },
        toogleAns2() {
            this.showAns2 = !this.showAns2;
        },
        toogleAns3() {
            this.showAns3 = !this.showAns3;
        },
        makeDisplay1() {
            this.display1 = true;
            this.display2 = false;
            this.display3 = false;
            this.activeEffectValue1 = true;
            this.activeEffectValue2 = false;
            this.activeEffectValue3 = false;
        },
        makeDisplay2() {
            this.display1 = false;
            this.display2 = true;
            this.display3 = false;
            this.activeEffectValue1 = false;
            this.activeEffectValue2 = true;
            this.activeEffectValue3 = false;

        },
        makeDisplay3() {
            this.display1 = false;
            this.display2 = false;
            this.display3 = true;
            this.activeEffectValue1 = false;
            this.activeEffectValue2 = false;
            this.activeEffectValue3 = true;
        },
        mobile() {
            // window.innerHeight
            console.log(this.windowWidth + "FAQ");
            if(this.windowWidth > 1000) {
                this.displayWeb = true;
                this.displayMobile = false;
            } else {
                this.displayWeb = false;
                this.displayMobile = true;
            }
            // this.display = !this.display;
        }
    }
}
</script> 



<style scoped>
.collapsible {
  background-color: transparent;
  /* opacity: 0; */
  color: rgb(255, 255, 255);
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  /* text-align: left; */
  outline: none;
  /* font-size: 15px; */
}

.collapsible h2 {
    /* opacity: 1; */
    color: rgb(255, 255, 255);
}

/* .active, .collapsible:hover {
   color: #555; 
} */

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  text-align: left;
  /* background-color: #f1f1f1; */
}
/* xx---xx---xx---xx */

/* Style the tab */
.tab {
  float: left;
  /* border: 1px solid #ccc; */
  /* background-color: #f1f1f1; */
  width: fit-content;
  /* height: 300px; */
  height: fit-content;
  margin-top: 4%;
}

/* Style the buttons inside the tab */
.tab button {
  display: block;
  background-color: inherit;
  color: black;
  /* width: 100%; */
  width: fit-content;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  /* transition: 0.1s; */
  font-size: 90%;
  font-weight: 300;
  height: fit-content;
  border-radius: 10px;
  margin-bottom: 1px;
}

/* Change background color of buttons on hover */
.tab  .icon-text-style:hover {
  background-color: #40af01;
  color: white;
  cursor: pointer;
}
.tab  i {
    margin: 1px;
}



/* Create an active/current "tab button" class */
.tab .active1 {
  background-color: #40af01;
  color: white;
}
.tab .active2 {
    background-color: #40af01;
    color: white;
}
.tab .active3 {
    background-color: #40af01;
    color: white;
}

.activeStyle, {
  background-color: #40af01;
  color: white;
}
/* Style the tab content */
.tabcontent {
  float: left;
  padding: 0px 12px;
  /* border: 1px solid #ccc; */
  width: 80%;
  border-left: none;
  height: 300px;
  margin-top: 2%;
  margin-left: 2%;
}

.icon-style {
    font-size: 30px;
    margin: 5px;
    margin-right: 10px;
    background-color: #40af01; 
    padding: 10px;
    color: white;
    border-radius: 10px;
}

.icon-text-style {
    font-size: 113%;
    /* margin: 5px; */
    margin-right: 10px;
    background-color: white; 
    padding: 10px;
    color: black;
    border-radius: 6px;
}

@media (max-width: 992px) {
    .text-container {
        /* align-items: center; */
        /* margin: 0 auto; */
    }
    .text-container .tab {
        margin: 0 auto;
    }
}
</style>
<template>
    <app-nav></app-nav>
    
    <app-header></app-header>

    <app-feature></app-feature>
    <app-howitworks></app-howitworks>
    <app-testimonials></app-testimonials><app-faq></app-faq>
    <app-contact></app-contact>
    <app-footer></app-footer>
    <app-bottom></app-bottom>
</template>


<script>
import Nav from './components/Nav.vue'
import Header from './components/Header.vue'
import Feature from './components/Features.vue'
import Howitworks from './components/Howitworks.vue'
import Testimonials from './components/Testimonials.vue'
import Faq from './components/Faq.vue'
import Contact from './components/Contact.vue'
import Footer from './components/Footer.vue'
import Bottom from './components/Bottom.vue'

export default {
    components: {
        'app-nav': Nav,
        'app-header': Header,
        'app-feature': Feature,
        'app-howitworks': Howitworks,
        'app-testimonials': Testimonials,
        'app-faq': Faq,
        'app-contact': Contact,
        'app-footer': Footer,
        'app-bottom': Bottom
    }
}
</script>



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.logo {
    width: 50px;
    height: 50px;
}

.feature-box {
    text-align: left;
}
.faq-box {
    text-align: left;
}


</style>


<template>
<nav id="navbarExample" class="navbar navbar-expand-lg fixed-top navbar-light" aria-label="Main navigation">
    <div class="container">
        <!-- <a class="navbar-brand logo-text" href="#">Masuk Mia</a>  -->
        <img src="assets/images/AintrieLogo.png" class="logo" alt="">
        <h4>Aintrie</h4>
        <button class="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
            <ul class="navbar-nav ms-auto navbar-nav-scroll">
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" href="#header">Home</a>
                </li>
                <li class="nav-item">
                    <!-- <a class="nav-link" href="#services">Services</a> -->
                    <a class="nav-link" href="#services">Features</a>
                </li>
                <li class="nav-item">
                    <!-- <a class="nav-link" href="#details">Details</a> -->
                    <a class="nav-link" href="#details">How it works ?</a>
                </li>
                <li class="nav-item">
                    <!-- <a class="nav-link" href="#features">Features</a> -->
                    <a class="nav-link" href="#testimonials">Testimonials</a>
                </li>
                <li class="nav-item">
                    <!-- <a class="nav-link" href="#features">Features</a> -->
                    <a class="nav-link" href="#faq">FAQs</a>
                </li>
                <li class="nav-item">
                    <!-- <a class="nav-link" href="#features">Features</a> -->
                    <a class="nav-link" href="#contact">Contact</a>
                </li>
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">Drop</a>
                    <ul class="dropdown-menu" aria-labelledby="dropdown01">
                        <li><a class="dropdown-item" href="#">Article Details</a></li>
                        <li><div class="dropdown-divider"></div></li>
                        <li><a class="dropdown-item" href="#">Terms Conditions</a></li>
                        <li><div class="dropdown-divider"></div></li>
                        <li><a class="dropdown-item" href="#">Privacy Policy</a></li>
                    </ul>
                </li> -->
            </ul>
            <!-- <span class="nav-item">
                <a class="btn-solid-sm" href="#contact">Playstore</a>
            </span> -->
            <!-- <span class="nav-item">
                <a class="btn-solid-sm" href="#contact">App store</a>
            </span> -->
        </div> 
    </div> 
</nav>
</template>
